<template>
  <div
    class="data-card"
    :class="{ card: listType == 'card', list: listType == 'list' }"
    @click="jump"
  >
    <div class="cover">
      <img
        :src="
          dataSource.liveCover || require(`@/assets/image/mine/live_cover.png`)
        "
        alt="cover"
      />
      <div class="time" v-if="liveType == 1">
        <CountDown
          :timestamp="
            currentTime < dataSource.startTime
              ? dataSource.startTime
              : dataSource.endTime
          "
        >
          <template #time="{ time }">
            <div class="time-content">
              <img
                class="wait"
                src="@/assets/image/mine/exam_1.png"
                alt="wait"
              />
              <template v-if="currentTime < dataSource.startTime">
                {{ $t("from_start") }}：</template
              >
              <!-- 距开始： -->
              <template v-else> {{ $t("from_end") }}：</template>
              <!-- 距结束： -->
              {{ time.d }}{{ $t("Pub_Day") }}{{ time.h }}:{{ time.m }}:{{
                time.s
              }}
            </div>
          </template>
        </CountDown>
      </div>
    </div>
    <div class="detail">
      <h5>
        <template v-if="listType == 'list'">
          <template v-if="liveType == 1">
            <span
              class="status bg-blue"
              v-if="currentTime < dataSource.startTime"
            >
              {{ $t("Pub_Tab_NotStart")
              }}<!-- 未开始 -->
            </span>
            <span class="status bg-green" v-else>
              {{ $t("XB_UnderWay")
              }}<!-- 进行中 -->
            </span>
          </template>
          <template v-else>
            <span class="status bg-green" v-if="dataSource.progress == 100">
              {{ $t("CM_Completed")
              }}<!-- 已完成 -->
            </span>
            <span class="status bg-red" v-else-if="dataSource.progress > 0">
              {{ $t("CM_NotCompleted")
              }}<!-- 未完成 -->
            </span>
            <span class="status bg-gray" v-else-if="dataSource.progress == 0">
              {{ $t("CM_NoTakePartIn")
              }}<!-- 未参与 -->
            </span>
          </template>
        </template>
        <a-popover trigger="hover">
          <template #content>
            <div class="popover-content">
              {{ dataSource.taskName }}
            </div>
          </template>
          <div class="tit">
            {{ dataSource.taskName }}
          </div>
        </a-popover>
      </h5>
      <div class="row mt12">
        <span
          >{{ $t("live.speaker2") }}：<OpenData
            type="userName"
            :openid="dataSource.lecturerName"
            v-if="dataSource.lecturerType == 1"
          /><span v-else>{{ dataSource.lecturerName }}</span>
          <!-- 主讲人 --></span
        >
        <span class="split"></span>
        <span
          >{{ $t("live.participant") }}：{{
            $t("signup.people", [dataSource.users])
          }}
          <!-- 参与者 --></span
        >
      </div>
      <div class="row">
        <span v-show="listType == 'list'">{{ $t("live.live_time") }}：</span
        ><img
          class="icon"
          v-show="listType == 'card'"
          src="@/assets/image/mine/time.png"
          alt="time"
        /><span class="fs12"
          >{{ dateFormat(dataSource.startTime) }} ～
          {{ dateFormat(dataSource.endTime) }}</span
        >
        <!-- 直播时间 -->
      </div>
      <div class="row" v-show="listType == 'card'">
        {{ $t("CM_Status") }}：<!-- 状态 -->
        <template v-if="liveType == 1">
          <span class="blue" v-if="currentTime < dataSource.startTime">
            {{ $t("Pub_Tab_NotStart")
            }}<!-- 未开始 -->
          </span>
          <span class="green" v-else>
            {{ $t("XB_UnderWay")
            }}<!-- 进行中 -->
          </span>
        </template>
        <template v-else>
          <span class="green" v-if="dataSource.progress == 100">
            {{ $t("CM_Completed")
            }}<!-- 已完成 -->
          </span>
          <span class="red" v-else-if="dataSource.progress > 0">
            {{ $t("CM_NotCompleted")
            }}<!-- 未完成 -->
          </span>
          <span class="gray" v-else-if="dataSource.progress == 0">
            {{ $t("CM_NoTakePartIn")
            }}<!-- 未参与 -->
          </span>
        </template>
      </div>
      <div class="row" v-if="listType == 'list' && dataSource.taskType == 1">
        {{ $t("LB_Doc_Source") }}：<!-- 来源 -->{{ dataSource.taskName }}
      </div>
    </div>
    <div class="btns" v-show="listType == 'list'">
      <div class="btn">
        {{ $t("view_detail") }}
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { dateFormat } from "@/utils/tools";
import CountDown from "@/components/CountDown.vue";
import OpenData from "@/components/OpenData.vue";
export default {
  name: "LiveCard",
  components: {
    CountDown,
    OpenData,
  },
  props: {
    newWindow: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: "card",
    },
    dataSource: {
      type: Object,
      default: {},
    },
    liveType: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const router = useRouter();

    const jump = () => {
      if (props.newWindow) {
        window.open(
          `/live/detail?ddtab=true&id=${props.dataSource.taskId}&did=${props.dataSource.detailId}`
        );
      } else {
        router.push({
          path: "/live/detail",
          query: {
            id: props.dataSource.taskId,
            did: props.dataSource.detailId,
          },
        });
      }
    };

    return {
      dateFormat,
      currentTime: Date.parse(new Date()) / 1000,
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
.data-card {
  .cover {
    position: relative;
    .time {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 38px;
      padding: 0 12px;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 16px;
      color: #fff;
      line-height: 38px;
      &-content {
        .mixinFlex(flex-start; center);
        .wait {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
    }
  }
  .tit{
    width: 450px;
    .mixinEllipsis(26px);
  }
}
</style>
